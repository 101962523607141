<template>
  <v-skeleton-loader
      v-bind="$attrs"
      type="card, paragraph, actions"
      tile
  ></v-skeleton-loader>
</template>

<script>
export default {
  name: "ProductCardSkeleton"
}
</script>

<style scoped>

</style>
