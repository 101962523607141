
import  {defineComponent} from "vue";
import {mapActions, mapState} from "vuex";
import ProductItem from "@/components/product/ProductItem.vue";
import {Route} from "vue-router";
import PageLayout from "@/components/common/PageLayout.vue";
import ProductListSkeleton from "@/components/product/ProductListSkeleton.vue";

export default defineComponent({
  name: 'Catalogue',
  components: {
    ProductListSkeleton,
    PageLayout,
    ProductItem,
  },
  computed: {
    ...mapState('product', {
      products: 'items',
      loading: 'loading',
      pagination: 'pagination',
      meta: 'meta'
    }),
    currentCategory(): string {
      return this.breadcrumbs ? this.breadcrumbs[this.breadcrumbs.length - 1]?.name : 'Всі пропрозиції';
    },
    breadcrumbs(): any {
      return this.meta?.breadcrumbs;
    }
  },
  methods: {
    ...mapActions('filter', {
      fetchFilter: 'fetch'
    }),
    ...mapActions('product', {
      fetchProduct: 'fetch',
      changePage: 'changePage',
      setQuery: 'setQuery'
    }),
    initialize(newRoute?: Route) {
      const route = newRoute || this.$route

      this.fetchFilter({
        query: {category: route.params.slug},
        values: route.query
      })
      this.setQuery({
        state: 'published',
        trashed: false,
        category: route.params.slug,
        page: 1,
        format: 'raw'
      })
      this.fetchProduct({
        ...route.query
      })
    },

  },
  watch: {
    $route(to, from) {
      this.initialize(to);
    },
  },
  mounted() {
    this.initialize();
  }
})
