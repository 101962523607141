<template>
  <v-row>
    <v-col
        cols="12"
        md="4"
        v-for="item in 10"
        :key="item"
    >
      <product-card-skeleton />
    </v-col>
  </v-row>
</template>

<script>
import ProductCardSkeleton from "./ProductCardSkeleton";

export default {
  name: "ProductListSkeleton",
  components: {ProductCardSkeleton}
}
</script>

<style scoped>

</style>
