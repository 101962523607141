<template>
  <span><slot></slot>%</span>
</template>

<script>
export default {
  name: "FormattedDiscount"
}
</script>

<style scoped>

</style>
