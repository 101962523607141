
import Vue from "vue";
import {ProductModel} from "@/store/models/Product";
import BaseBtn from "@/components/base/Button.vue";
import FormattedDiscount from "@/components/common/FormattedDiscount.vue";

export default Vue.extend({
  name: 'ProductItem',
  components: {FormattedDiscount, BaseBtn},
  props: {
    product: ProductModel
  },
  data() {
    return {
      productUrl: `/product/${this.product?.uuid}`
    }
  }
})
